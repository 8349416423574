@import "../../sass/vars"

.form-wrap
  width: 100%

.contactus-form
  display: flex
  flex-wrap: wrap
  gap: 10px 17px


  & > div
    flex: 40%
    display: flex
    flex-direction: column
    align-items: center

    &:nth-last-child(2)
      flex: 100%

    &:nth-last-child(1)
      flex: 300px 0
      align-items: baseline
      margin-top: 10px

    @media (max-width: 500px)
      flex: 100%


    &.note
      font: normal normal 300 24px/32px $sofia

    .error
      width: 100%
      color: #5f5222
      font: normal normal 300 14px/22px $sofia
      text-align: right
      margin-right: 5px

