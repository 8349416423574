@import "../../sass/vars"

.btn-submit
  color: #fff
  font-family: $sofia
  border-radius: 9999px
  box-shadow: none
  text-decoration: none
  padding: calc(0.667em + 2px) calc(1.333em + 2px)
  font-size: 1.125em
  background-color: #143562
  min-width: 160px
  border: none


  &:hover, &:focus
    background-color: #00b386
    cursor: pointer

  &:disabled
    background-color: #E4E4E4